<template>
  <div class="home">
    <!-- home block -->
    <!-- <static-home></static-home> -->
    <div v-append-html="pageContent"/>
    <modal-newsletter></modal-newsletter>
  </div>
</template>

<script>
// @ is an alias to /src
import ModalNewsletter from '@/components/Modal/ModalNewsletter'
/* import StaticHome from 'theme/BackOffice/StaticHome/Home' */
import CmsContent from '@/components/CmsContent'
import MetaHelper from '@/helpers/Meta'
export default {
  name: 'home',
  components: {
    CmsContent,
    /* StaticHome, */
    ModalNewsletter
  },
  head () {
    const metaHelper = new MetaHelper(this.$store)
    const title = this.$store.state.cms.meta.title || metaHelper.getTitle()
    const description = this.$store.state.cms.meta.description || metaHelper.getDescription()
    const keywords = this.$store.state.cms.meta.keywords || metaHelper.getKeyword()
    return {
      title: title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: description
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: keywords
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: keywords
      }]
    }
  },
  computed: {
    pageContent () {
      return this.$store.getters['cms/getContentPage']
    }
  },
  asyncData ({ store, redirect }) {
    return store.dispatch('cms/getPage', store.getters['storeConfig/homePage'])
      .then(() => {
        // dati strutturati
        let jsonLD = []
        if (store.state.storeConfig.config.seo_markup_seller &&
            store.state.storeConfig.config.seo_markup_seller.show === 'only_home_page' &&
            store.state.storeConfig.config.seo_markup_seller.json) {
          jsonLD.push(store.state.storeConfig.config.seo_markup_seller.json)
        }
        // speakable default
        if (store.state.storeConfig.config.seo_markup_speakable &&
            store.state.storeConfig.config.seo_markup_speakable.json_page) {
          jsonLD.push(store.state.storeConfig.config.seo_markup_speakable.json_page)
        }
        store.commit('ui/setJsonLD', jsonLD)
        store.commit('ui/setCanonical', `${process.env.VUE_APP_BASE_PATH}/${store.state.ui.storeViewCode}`)
      })
      .catch(() => {
        // redirect({ name: 'page-not-found', params: { lang: store.state.ui.storeViewCode } })
      })
  }
}
</script>
