<template>
  <transition name="fadeIn">
    <div class="modal-newsletter" v-if="showCtaNewsletter" ref="modalNewsletter">
      <div class="backdrop" @click.prevent="hideModal"></div>
      <div class="modal-newsletter__content">
        <button type="button" class="btn btn-icon close-modal" @click.prevent="hideModal">
          <i class="bcm-icon bcm-icon-04-close"></i>
        </button>
        <div class="modal-newsletter__content__body">
          <div class="wrapp-main-image">
            <div class="background-full" v-lazy:background-image="newsletterImage"></div>
          </div>
          <div class="wrapp-info">
            <div class="scrollable-content">
              <h2>{{ $t('newsletter.title') }}</h2>

              <div class="wrapp-form-newsletter">
                <div class="form-intro">
                  <p>{{ $t('newsletter.intro') }}</p>
                </div>
                <b-form class="form-newsletter" @submit.prevent="submitForm" data-vv-scope="form-cta-newsletter">
                  <b-form-group id="email" label-for="email">
                    <base-input
                      name="email"
                      v-model.trim="newsletter.email"
                      v-validate="'required|email'"
                      type="email"
                      :placeholder="$t('email')"
                      :validations="[
                        {
                          condition: errors.has('form-cta-newsletter.email'),
                          text: errors.first('form-cta-newsletter.email')
                        }
                      ]"
                    >
                    </base-input>
                    <div class="valid-feedback"><div> {{$t('newsletter_success')}} </div></div>
                  </b-form-group>
                  <b-form-group id="checkbox-privacy-newsletter" class="checkbox-privacy">
                    <base-check-box
                      name="newsletter-privacy"
                      v-validate="'required:true'"
                      v-model="newsletter.privacy"
                      :label="labelLinkPrivacy"
                      :validations="[
                        {
                          condition: errors.has('form-cta-newsletter.newsletter-privacy'),
                          text: errors.first('form-cta-newsletter.newsletter-privacy')
                        }
                      ]"
                    >
                    </base-check-box>
                  </b-form-group>
                  <div class="form-actions">
                    <button type="submit" class="btn btn-primary full-width">{{ $t('subscribe') }}</button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import Newsletter from '@/services/Newsletter'
// import { toast } from '@/modules/notifications'
import { EventBus } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
import Logger from '@/services/Logger'

export default {
  inject: ['$validator'],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newsletterImage: '',
      newsletter: {
        email: '',
        privacy: false
      },
      showCtaNewsletter: null
    }
  },
  mounted () {
    if (process.client) {
      let newsletterCookieVal = !!global.$cookies.get('hide-cta-newsletter')
      this.showCtaNewsletter = !newsletterCookieVal
    }
  },
  computed: {
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('termsMarketing', { linkPrivacy })
    }
  },
  methods: {
    clearForm () {
      this.newsletter.email = ''
      this.newsletter.privacy = false
      this.$validator.reset()
    },
    submitForm ($event) {
      const _this = this
      this.$validator.validateAll('form-cta-newsletter').then(isValid => {
        if (isValid) {
          return Newsletter.subscribeCustomer(
            _this.$store.state.ui.storeViewCode,
            { email: _this.newsletter.email, ip: _this.$store.state.user.ip }
          )
            .then((res) => {
              if (res.data !== 'OK') {
                throw new Error('error')
              }
              EventBus.$emit('track:newsletter', { mode: 'modal', $event })
              $('#email .valid-feedback').css('display', 'block')
              setTimeout(function () {
                _this.hideModal()
              }, 1500)
              // toast.success(_this.$t('newsletter_success'))
              _this.clearForm()
            })
            .catch((err) => {
              Logger.error(err)
              $('#email input').addClass('is-invalid')
              // $('.col-newsletter input.form-control').css('background-image', 'none')
              $('#email .invalid-feedback').html(_this.$t('newsletter_server_error'))
              setTimeout(function () {
                $('#email .invalid-feedback').html('')
              }, 2500)
              // toast.error(_this.$t('newsletter_error'))
              // _this.clearForm()
              // _this.hideModal()
            })
        }
      })
    },
    hideModal () {
      this.showCtaNewsletter = false
      this.closeNewsletterBlock()
    },
    closeNewsletterBlock () {
      global.$cookies.set('hide-cta-newsletter', true)
    }
  },
  components: {
    BaseInput,
    BaseCheckBox
  }
}
</script>
