var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fadeIn"}},[(_vm.showCtaNewsletter)?_c('div',{ref:"modalNewsletter",staticClass:"modal-newsletter"},[_c('div',{staticClass:"backdrop",on:{"click":function($event){$event.preventDefault();return _vm.hideModal($event)}}}),_c('div',{staticClass:"modal-newsletter__content"},[_c('button',{staticClass:"btn btn-icon close-modal",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.hideModal($event)}}},[_c('i',{staticClass:"bcm-icon bcm-icon-04-close"})]),_c('div',{staticClass:"modal-newsletter__content__body"},[_c('div',{staticClass:"wrapp-main-image"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.newsletterImage),expression:"newsletterImage",arg:"background-image"}],staticClass:"background-full"})]),_c('div',{staticClass:"wrapp-info"},[_c('div',{staticClass:"scrollable-content"},[_c('h2',[_vm._v(_vm._s(_vm.$t('newsletter.title')))]),_c('div',{staticClass:"wrapp-form-newsletter"},[_c('div',{staticClass:"form-intro"},[_c('p',[_vm._v(_vm._s(_vm.$t('newsletter.intro')))])]),_c('b-form',{staticClass:"form-newsletter",attrs:{"data-vv-scope":"form-cta-newsletter"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-form-group',{attrs:{"id":"email","label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"email","placeholder":_vm.$t('email'),"validations":[
                      {
                        condition: _vm.errors.has('form-cta-newsletter.email'),
                        text: _vm.errors.first('form-cta-newsletter.email')
                      }
                    ]},model:{value:(_vm.newsletter.email),callback:function ($$v) {_vm.$set(_vm.newsletter, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newsletter.email"}}),_c('div',{staticClass:"valid-feedback"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('newsletter_success'))+" ")])])],1),_c('b-form-group',{staticClass:"checkbox-privacy",attrs:{"id":"checkbox-privacy-newsletter"}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],attrs:{"name":"newsletter-privacy","label":_vm.labelLinkPrivacy,"validations":[
                      {
                        condition: _vm.errors.has('form-cta-newsletter.newsletter-privacy'),
                        text: _vm.errors.first('form-cta-newsletter.newsletter-privacy')
                      }
                    ]},model:{value:(_vm.newsletter.privacy),callback:function ($$v) {_vm.$set(_vm.newsletter, "privacy", $$v)},expression:"newsletter.privacy"}})],1),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary full-width",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('subscribe')))])])],1)],1)])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }